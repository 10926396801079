import React, { Component } from "react";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-slick";

import Socials from "./Socials";

// IMAGES
import Naaz from "../assets/naaz-2.jpg";
import NaazMobile from "../assets/naaz-mobile.jpg";
import Hoger from "../assets/hoger-artist2.jpg";
import HogerMobile from "../assets/hoger-mobile2.jpg";
import Renske from "../assets/renske.jpg";
import RenskeMobile from "../assets/renske-mobile.jpg";
import Tev from "../assets/tev.jpg";
import TevMobile from "../assets/tev-mobile.jpg";
import Mia from "../assets/girl2.jpg";
import MiaMobile from "../assets/girl-mobile.jpg";
import Asiah from "../assets/asiah.jpg";
import AsiahMobile from "../assets/asiah-mobile.jpg";
import Rockket from "../assets/rockket.jpg";
import RockketMobile from "../assets/rockket-mobile.jpg";
import TomFrane from "../assets/tom-frane.jpg";
import TomFraneMobile from "../assets/tom-frane-mobile.jpg";


const Root = styled.div`
  position: relative;
`;

const Image = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 768px) {
    background-size: auto 100%;
    background-image: url(${(props) => props.imageMobile});
  }
`;

const rand = Math.floor(Math.random() * 3); // random number

const settings = {
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  initialSlide: rand,
  pauseOnHover: false,
};

const ImageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0;
`;

export default class Slider extends Component {
  render() {
    return (
      <Root>
        <Carousel {...settings}>
        <ImageContainer>
            <Image image={Asiah} imageMobile={AsiahMobile} alt="Asiah" />
          </ImageContainer>
          <ImageContainer>
            <Image image={TomFrane} imageMobile={TomFraneMobile} alt="Tom Frane" />
          </ImageContainer>
          <ImageContainer>
            <Image image={Rockket} imageMobile={RockketMobile} alt="Rockket" />
          </ImageContainer>
          <ImageContainer>
            <Image image={Tev} imageMobile={TevMobile} alt={"Tev"} />
          </ImageContainer>
          <ImageContainer>
            <Image image={Renske} imageMobile={RenskeMobile} alt={"Renske"} />
          </ImageContainer>
          <ImageContainer>
            <Image image={Mia} imageMobile={MiaMobile} alt={"Mia"} />
          </ImageContainer>
          <ImageContainer>
            <Image image={Naaz} imageMobile={NaazMobile} alt="Naaz" />
          </ImageContainer>
          <ImageContainer>
            <Image image={Hoger} imageMobile={HogerMobile} alt={"Hoger"} />
          </ImageContainer>
        </Carousel>
        <Socials />
      </Root>
    );
  }
}
