import React, { Component } from "react";
import styled from "styled-components";

import Naaz from "../assets/naaz-2.jpg";
import Renske from "../assets/renske.jpg";
import Tev from "../assets/tev.jpg";
import Hoger from "../assets/hoger-artist.jpg";
import Mia from "../assets/girl2.jpg";
import Asiah from "../assets/asiah.jpg";
import TomFrane from "../assets/tom-frane.jpg";
import Rockket from "../assets/rockket-roster.jpg";
import Roster from "../component/Roster";

const Root = styled.div`
  display: flex;
  margin-top: 70px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 890px) {
  }
`;

const Title = styled.h1``;

const RosterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  width: 60%;
  height: 100vh;
  padding-bottom: 100px;
  justify-content: space-between;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 1380px) {
    width: 90%;
  }

  @media only screen and (max-width: 890px) {
    align-items: center;
    justify-content: center;
    width: 90%;
  }
`;

export default class OurRoster extends Component {
  render() {
    return (
      <Root>
        <Title>OUR ROSTER</Title>
        <RosterContainer>
          <Roster image={Asiah} name="Asiah" />
          <Roster image={TomFrane} name="Tom Frane" />
          <Roster image={Rockket} name="ROCKKET" />
          <Roster image={Tev} name="Tev" />
          <Roster image={Renske} name="Renske" />
          <Roster image={Mia} name="Mia" />
          <Roster image={Naaz} name="Naaz" />
          <Roster image={Hoger} name="Hoger" last />
        </RosterContainer>
      </Root>
    );
  }
}
